
import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import { mainModule } from '@/store/main';
import { IConsumerCreate } from '@/interfaces';
import {
  dispatchUpdateConsumer,
  dispatchCreateConsumer,
} from '@/store/consumers/actions';
import { readOneConsumer } from '@/store/consumers/getters';

@Component
export default class CreateConsumer extends Vue {
  private rules = {
    required: (value) => value.trim().length > 0 || 'Required',
    unique: (value) => this.isUnique() || 'Must be unique',
  };
  private appId = 0;
  private consumerId = 0;
  private valid = false;
  private unique = true;
  private consumer: IConsumerCreate = { name: '', is_active: true };

  private mounted() {
    this.update();
  }

  private beforeRouteUpdate(to, from, next) {
    next();
    this.update();
  }

  private update() {
    this.appId = Number(this.$router.currentRoute.params.id);
    this.consumerId = Number(this.$router.currentRoute.params.consumerId ?? 0);
    if (this.consumerId > 0) {
      this.consumer = readOneConsumer(this.$store)(this.consumerId)!;
    }
  }

  private cancel() {
    this.$router.back();
  }

  private async submit() {
    await this.checkUniqueConsumer();
    if ((this.$refs.form as any).validate()) {
      this.consumer.name = this.consumer.name.trim();
      if (this.consumerId > 0) {
        await dispatchUpdateConsumer(this.$store, {
          appId: this.appId,
          consumerId: this.consumerId,
          consumer: this.consumer,
        });
      } else {
        await dispatchCreateConsumer(this.$store, {
          appId: this.appId,
          consumer: this.consumer,
        });
      }
      this.$router.back();
    }
  }

  private onNameFocus() {
    this.unique = true;
    (this.$refs.form as any).validate();
  }

  private isUnique() {
    return this.unique;
  }

  private async checkUniqueConsumer() {
    await api
      .checkUniqueConsumer(
        mainModule.state.token,
        this.appId,
        this.consumerId,
        this.consumer.name,
      )
      .then((res) => {
        this.unique = res.data;
      });
  }
}
